import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { GatsbyImageData, Img } from "wmk-image";
import { RichText, RichTextReact } from "wmk-rich-text";
import { HomeTopicsQuery } from "../../../fragments/NodeHomeTopicsFields";
import { IconTextQuery } from "../../../fragments/NodeIconTextFields";
import options from "../../../richText/richTextOptions";
import BackToTop from "../../common/BackToTop";
import StatusPopup from "../../layout/StatusPopup";
import DownloadShareBar from "./DownloadShareBar";
import HomeHero from "./HomeHero";
import IconText from "./IconText";
import TileLock from "./TileLock";
import TopicTile from "./TopicTile";
// import RichText from "../../../richText/Richtext"

export interface HomeData {
  homeHeroAndIntro: {
    title: string;
    subtitle: string;
    contentfulid: string;
    richText: {
      raw: string;
    };
  };
  homeSecondaryCopy: {
    richText: {
      raw: string;
    };
  };
  topics: {
    edges: {
      node: HomeTopicsQuery;
    }[];
  };
  hero: {
    edges: {
      node: {
        title: string;
        gatsbyImageData: GatsbyImageData;
      };
    }[];
  };
  downloadIcon: {
    title: string;
    gatsbyImageData: GatsbyImageData;
  };
  downloadLink: {
    title: string;
    file: {
      url: string;
    };
  };
  shareIcon: {
    title: string;
    gatsbyImageData: GatsbyImageData;
  };
  iconText: {
    edges: {
      node: IconTextQuery;
    }[];
  };
  sharePopup: {
    title: string;
    subtitle: string;
    contentfulid: string;
  };
}

// const Wrap = {};
const CopyWrap = styled(Container)`
  padding: 4rem 15%;
`;
const IconTextWrap = styled(Container)`
  // margin: 3rem auto;
`;
const TilesWrap = styled(Container)`
  padding: 0 5%;
  margin: 0 auto 5rem auto;
  position: relative;
  @media only screen and (min-width: 1900px) {
    max-width: 1800px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
`;

const HomePage = ({
  homeData,
  heroImages,
  toggleShare,
  iconTexts,
  sharePopup,
  topics,
  showShare,
}: {
  homeData: HomeData;
  heroImages: Img[];
  toggleShare: Function;
  iconTexts: IconTextQuery[];
  sharePopup: { title: string; subtitle: string; contentfulid: string };
  topics: HomeTopicsQuery[];
  showShare: boolean;
}) => {
  const [showFormLock, setShowFormLock] = useState(false);
  const [topicAfterUnlock, setTopicAfterUnlock] = useState(
    "transparency-establishes-trust"
  );
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true);
    }
  }, []);
  const topicReadMoreClick = (slug: string) => {
    setShowFormLock(true);
    setTopicAfterUnlock(slug);
    // console.log('topicAfterUnlock in HomePage.js click function: ', topicAfterUnlock);
  };
  // console.log('topicAfterUnlock in HomePage.js definition: ', topicAfterUnlock);
  const raw = homeData?.homeHeroAndIntro?.richText?.raw
  const initialCopy = new RichText(homeData?.homeHeroAndIntro?.richText)
  const secondaryCopy = new RichText(homeData?.homeSecondaryCopy?.richText)
  const DlIcon = new Img(homeData?.downloadIcon)
  return (
    <>
      <HomeHero
        images={heroImages}
        title={homeData.homeHeroAndIntro.title}
        subtitle={homeData.homeHeroAndIntro.subtitle}
      />
      <DownloadShareBar
        DlLink={homeData.downloadLink}
        DlIcon={DlIcon}
        // ShareIcon={homeData.shareIcon}
        // toggleShare={toggleShare}
      />
      <CopyWrap>
        {raw && <RichTextReact content={initialCopy} options={options} />}
        {/* {documentToReactComponents(
          homeData.homeHeroAndIntro.richText.raw,
          options
        )} */}
      </CopyWrap>
      <IconTextWrap>
        <Row>
          {iconTexts.map((piece, i) => {
            const { title, text, icon } = piece;
            return (
              <Col xs={12} sm={4} key={piece.title + `_` + i}>
                <IconText title={title} text={text} icon={icon} />
              </Col>
            );
          })}
        </Row>
      </IconTextWrap>
      <CopyWrap>
      {raw && <RichTextReact content={secondaryCopy} options={options} />}
        {/* {documentToReactComponents(
          homeData.homeSecondaryCopy.richText.json,
          options
        )} */}
      </CopyWrap>
      <TilesWrap fluid id="tiles-cont-wrap">
        <Row>
          {topics.map((topic, i) => {
            const { title, slug, sortOrder, mainImage } = topic;
            const image = new Img(mainImage)
            // const { gatsbyImageData } = mainImage ? mainImage : null;
            return (
              <TopicTile
                title={title}
                slug={slug}
                sortOrder={sortOrder}
                mainImage={image}
                fluid={image}
                key={topic.title + i}
                topicReadMoreClick={() => topicReadMoreClick(slug)}
              />
            );
          })}
        </Row>
        {showFormLock && <TileLock topicAfterUnlock={topicAfterUnlock} />}
      </TilesWrap>
      <BackToTop />
      {showShare && (
        <StatusPopup
          downloadTY
          toggle={toggleShare}
          textLineOne={sharePopup.title}
          textLineTwo={sharePopup.subtitle}
        />
      )}
      {/* REMOVE THIS BUTTON BEFORE GOING LIVE */}
      {/* {!unlocked && <DevUnlock onClick={() => unlock()} ><p>Click to Unlock Topic Pages</p><p>(button will be removed before going live)</p></DevUnlock>}  */}
      {/* REMOVE THIS BUTTON BEFORE GOING LIVE */}
    </>
  );
};

export default HomePage;
