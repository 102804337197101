import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import { Img, WmkImage } from "wmk-image";

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: 25rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .title-wrap {
    position: relative;
    z-index: 10;
    text-align: center;
    width: 100%;
    color: ${colors.hex("white")};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 2rem;
      font-weight: bold;
      text-shadow: -6px 4px 8px ${colors.hex("text")};
      white-space: nowrap;
      padding-left: 10px;
    }
    h2 {
      width: auto;
      padding: 0 35px;
      clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%);
      background: ${colors.rgba("codGray", 0.89)};
      font-size: 1.1rem;
      font-weight: 200;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: ${colors.hex("secondary")};
        font-size: 2.2rem;
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .gatsby-image-wrapper {
      height: auto; // this line forces the background image to be positioned with the top of the image at the top of the hero
    }
    // .title-wrap {
    //   top: 50px;
    // }
  }
  @media only screen and (max-width: 767px) {
    .title-wrap {
      h1 {
        font-size: 1.5rem;
      }
      h2 {
        font-size: 0.9rem;
        padding: 0 25px;
        span {
          font-size: 1.6rem;
        }
      }
    }
  }
`;

const HomeHero = ({
  images,
  title,
  subtitle,
}: {
  images: Img[];
  title: string;
  subtitle: string;
}) => {
  const [screenSize, setScreenSize] = useState(0);
  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });
  const onResize = () => {
    const width = window.outerWidth;
    setScreenSize(width);
    // console.log("screenSize: ", screenSize)
  };
  let currentImage =
    images && screenSize <= 576 && images[0]
      ? images[0]
      : screenSize > 576 && screenSize <= 768 && images[1]
      ? images[1]
      : screenSize > 768 && screenSize <= 1200 && images[2]
      ? images[2]
      : images[3] && images[3];
  return (
    <Wrap id="hero" style={{ background: colors.hex("black") }}>
      <WmkImage image={currentImage} />
      {/* <Img fluid={currentImage.fluid} alt={currentImage.title} /> */}
      <div className="title-wrap">
        <h1>{title}</h1>
        <h2>
          <span>6</span> {subtitle}
        </h2>
      </div>
    </Wrap>
  );
};

export default HomeHero;
