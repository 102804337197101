import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors, WMKGradient } from "../../vars/palette";
import Logo from "../common/Logo";

const StatusPopup = ({
  toggle,
  textLineOne,
  textLineTwo,
  formTY,
  downloadTY,
}: {
  toggle: Function;
  textLineOne: string;
  textLineTwo: string;
  formTY?: boolean;
  downloadTY?: boolean;
}) => {
  return (
    <Wrap>
      <div
        className={
          formTY
            ? "content-wrap form-thank-you"
            : downloadTY
            ? "content-wrap dl-thank-you"
            : "content-wrap"
        }
      >
        <p>{textLineOne && textLineOne}</p>
        <p>{textLineTwo && textLineTwo}</p>
        {formTY && (
          <div className="links-wrap">
            <button onClick={() => toggle()}>
              <WmkLink to={`/`}>ESG Home</WmkLink>
            </button>
            <a href="https://wmkagency.com" className="simple-anchor">
              About Watermark
            </a>
          </div>
        )}

        <button className="close" onClick={() => toggle()}>
          X
        </button>
        <Logo white />
      </div>
    </Wrap>
  );
};

export default StatusPopup;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${colors.rgba("black", 0.8)};
  .content-wrap {
    ${WMKGradient.grad()}
    position: relative;
    z-index: 1001;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 10%;
    &.dl-thank-you {
      width: 60%;
      height: 50%;
    }
    p {
      position: relative;
      z-index: 50000;
      color: ${colors.hex("white")};
      font-size: 2rem;
      margin-bottom: 0;
    }
    button.close {
      position: absolute;
      top: 25px;
      left: 30px;
      color: ${colors.hex("white")};
      font-size: 2rem;
      background: none;
      border: none;
    }
    .links-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      a.simple-anchor {
        position: relative;
        z-index: 10000000;
        color: ${colors.hex("white")};
        padding: 3px 40px;
        margin-right: 30px;
        margin-bottom: 1.5rem;
        border: 2px solid ${colors.hex("white")};
        border-radius: 100px;
        background: none;
        transition: background 0.3s ease;
        :hover {
          text-decoration: none;
          background: ${colors.rgba("white", 0.2)};
          transition: background 0.3s ease;
        }
      }
      button {
        padding: 20px;
        background: none;
        border: none;
        margin-right: 20px;
        margin-bottom: 1.5rem;
        a {
          color: ${colors.hex("white")};
          padding: 6px 40px;
          border: 2px solid ${colors.hex("white")};
          border-radius: 100px;
          background: none;
          transition: background 0.3s ease;
          :hover {
            text-decoration: none;
            background: ${colors.rgba("white", 0.2)};
            transition: background 0.3s ease;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .content-wrap {
      p {
        font-size: 3rem;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .content-wrap {
      height: 15rem;
      &.form-thank-you {
        height: 90%;
      }
      p {
        font-size: 1.5rem;
      }
      button {
        top: 10px;
        left: 15px;
        font-size: 1rem;
      }
    }
  }
`;
