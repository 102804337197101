import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { HubUserContext, isValidUser } from "../../context/HubspotContext";
import { Col } from "react-bootstrap";
// import Img from "gatsby-image";
// import { WmkLink } from 'wmk-lib'
import { colors } from "../../../vars/palette";
import { WmkLink } from "wmk-link";
import { WmkImage, Img } from "wmk-image";

const WrapCol = styled(Col)`
  height: 25rem;
  margin: 1rem 0;
  .tile-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      position: relative;
      z-index: 0;
      width: 100%;
      height: 100%;
    }
    .title-link-bar {
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 15px 0 15px 20%;
      background: rgba(15, 27, 41, 0.88);
      color: ${colors.hex("white")};
      h4 {
        font-size: 1rem;
        position: relative;
        margin-top: 0.5rem;
        span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          border: 0.5px solid ${colors.hex("white")};
          border-radius: 50%;
          position: absolute;
          left: -30px;
          top: -2px;
          font-size: 1rem;
          white-space: nowrap;
        }
      }
      a,
      button {
        color: #f15a24;
        font-size: 0.75rem;
        padding: 3px 25px;
        border: 1px solid #f15a24;
        border-radius: 50px;
        display: block;
        width: 122px;
        margin: 1rem 0;
        background: none;
        transition: background 0.3s ease;
        transition: all 0.3s ease;
        white-space: nowrap;
        text-decoration: none;
        :hover {
          text-decoration: none;
          background: rgba(255, 255, 255, 0.25);
          transition: background 0.3s ease;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
`;

const TopicTile = ({
  title,
  slug,
  sortOrder,
  mainImage,
  fluid,
  topicReadMoreClick,
}: {
  title: string;
  slug: string;
  sortOrder: number;
  mainImage: Img;
  fluid: Img;
  topicReadMoreClick: Function;
}) => {
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true);
    }
  }, []);

  return (
    <WrapCol sm={12} md={6}>
      <div className="tile-wrap">
        <WmkImage image={fluid} />
        {/* <Img fluid={fluid} alt={mainImage.title} /> */}
        <div className="title-link-bar">
          <h4>
            <span>{sortOrder + ` `}</span>
            {title}
          </h4>
          {/* <HubUserContext.Consumer>
            {(data) => {
              const { user } = data; */}
          {/* // console.log(user);
              //console.log("in tile:", user, reset); */}
          {!validUser ? (
            <button
              onClick={() => topicReadMoreClick(slug)}
              className="topic-button-form-popup"
            >
              READ MORE
            </button>
          ) : (
            <WmkLink to={"/" + slug}>READ MORE</WmkLink>
          )}
          {/* }}
          </HubUserContext.Consumer> */}
        </div>
      </div>
    </WrapCol>
  );
};

export default TopicTile;
