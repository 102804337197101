import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import Logo from "../../common/Logo";
import HubspotForm from "react-hubspot-form";
import { HubUserContext, isValidUser } from "../../context/HubspotContext";

import $ from "jquery";
import { colors, WMKGradient75opacity } from "../../../vars/palette";
// import { UserContext } from "../../context/UserContext";
import { string } from "prop-types";
import { Link } from "gatsby";

const Wrap = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.9)
  );
  box-shadow: 0 -20px 20px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .form-wrap {
    position: relative;
    // margin-top: -17rem;
    ${WMKGradient75opacity.grad()}
    width: 100%;
    height: auto;
    padding: 2rem 20vw;
    color: ${colors.hex("white")};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    form {
      position: relative;
      z-index: 10;
      // // A/B TESTING - REMOVE UNNECESSARY LAST NAME AND COMPANY FIELDS IN HOPES THAT PEOPLE WILL BE MORE LIKELY TO FILL OUT FORM IF IT'S SMALLER
      // .hs-lastname, .hs-company {
      //   display: none;
      // }
      label {
        margin-bottom: 0;
        margin-top: 0.5rem;
      }
      input {
        border-radius: 50px;
        border: none !important;
        padding: 3px 10px;
        box-shadow: -2px 2px 4px #666666;
        ::placeholder {
          color: #666666;
        }
      }
      input[type="submit"] {
        background: none;
        color: ${colors.hex("white")};
        border: 2px solid white !important;
        margin: 1rem 0 2rem 0;
        transition: all 0.3s ease;
        :hover {
          background: ${colors.hex("white")};
          color: #e48c2b;
          transition: all 0.3s ease;
        }
      }
      span.hs-form-required {
        display: none;
      }
      ul.hs-error-msgs {
        margin-bottom: -0.5rem;
      }
    }
  }
  &.hidden {
    display: none;
  }
`;

const TileLock = ({ topicAfterUnlock }: { topicAfterUnlock: string }) => {
  // console.log('topicAfterUnlock in TileLock.js: ', topicAfterUnlock);
  const [dataLayerObj, setDataLayerObj] = useState<
    // | { 0: string; 1: string; 2: boolean }[]
    // | { event: string; "gtm.uniqueEventId": number }[]
    { event: string; campaign: string; ABVersion: string }[]
  >([]);
  const [formUnlocked, setFormUnlocked] = useState(false);
  const [form, setForm] = useState();
  const [redirect, setRedirect] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
  });

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setFormUnlocked(true);
    }
  }, []);

  useEffect(() => {
    if (window && userData?.email) {
      localStorage.setItem("userFirstName", userData.firstName);
      localStorage.setItem("userLastName", userData.lastName);
      localStorage.setItem("userEmail", userData.email);
      localStorage.setItem("userCompany", userData.company);
    }
  }, [userData]);

  const handleForm = (event: MessageEvent) => {
    // console.log("event", event);
    if (event.data.eventName === "onFormSubmit") {
      if (event.data.id === "07cf71e1-0e4c-42f0-936f-ec01a09ff16d") {
        localStorage.setItem("hasCompletedForm", "true");
        setTimeout(() => {
          setFormUnlocked(true);
        }, 1000);
      }
      if (event.data.data.length) {
        let first;
        let last;
        let mail;
        let company;
        event.data.data.map((formData) => {
          if (formData.name === "firstname") {
            first = formData.value;
          } else if (formData.name === "lastname") {
            last = formData.value;
          } else if (formData.name === "email") {
            mail = formData.value;
          } else if (formData.name === "company") {
            company = formData.value;
          }
        });
        setUserData({
          firstName: first,
          lastName: last,
          email: mail,
          company: company,
        });
      }
    }
  };

  // useEffect(() => {
  //   console.log("userData", userData);
  // }, [userData]);

  useEffect(() => {
    window.addEventListener("message", handleForm);
    return () => {
      window.removeEventListener("message", handleForm);
    };
  }, []);

  // const user = useContext(UserContext);

  // useEffect(() => {
  //   user.setFirstName(userData.firstName);
  //   user.setLastName(userData.lastName);
  //   user.setEmail(userData.email);
  //   user.setCompany(userData.company);
  // }, [userData]);

  useEffect(() => {
    if (redirect && userData.email) {
      // console.log("userData.email", userData.email);
      // console.log("topicAfterUnlock", topicAfterUnlock);
      setTimeout(() => {
        // <Link to={topicAfterUnlock} />
        // window.history.pushState({}, "", topicAfterUnlock);
        // window.history.go(1)
        window.location.assign(topicAfterUnlock);
      }, 1000);
    }
  }, [redirect, userData.email]);

  useEffect(() => {
    if (formUnlocked) {
      setRedirect(true);
    }
  }, [formUnlocked]);

  const gtm = {
    event: "formSubmission",
    campaign: "esg",
    ABVersion: "popup-form",
  };

  const formRef = useRef();
  useEffect(() => {
    const timer = setTimeout(() => {
      const theForm = formRef.current;
      setForm(theForm);
    }, 2000);
    return clearTimeout(timer);
  }, [setForm]);

  // useEffect(() => {
  //   console.log("form", form);
  // }, [form]);

  useEffect(() => {
    const globalDataLayer = window.dataLayer;
    // console.log("globalDataLayer", globalDataLayer);
    if (globalDataLayer && Array.isArray(globalDataLayer)) {
      setDataLayerObj(globalDataLayer);
    }
  }, []);

  //console.log(form)
  useEffect(() => {
    const globalDataLayer = window.dataLayer;
    // console.log("globalDataLayer2", globalDataLayer)
    if (globalDataLayer && Array.isArray(globalDataLayer)) {
      setDataLayerObj(globalDataLayer);
    }
  }, []);

  return !formUnlocked ? (
    // <HubUserContext.Consumer>
    //   {(data) => {
    //     // console.log("data", data)
    //     const { user, reset } = data;
    //     // console.log("user",user);
    //     // console.log("in tile:", user, reset);
    //     // console.log('topicAfterUnlock in TileLock.js jsx: ', topicAfterUnlock);
    // return !isValidUser(user) && !formUnlocked ? (
    <Wrap id="tile-lock-wrap">
      <div className="form-wrap" id="formWrap">
        <h2>Unlock the 6 Reasons Why Your Organization Should Adapt</h2>
        <HubspotForm
          ref={formRef}
          portalId="526959"
          formId="07cf71e1-0e4c-42f0-936f-ec01a09ff16d"
          onSubmit={(values: { name: string; values: string }[]) => {
            // console.log("values", values)
            //setForm(formRef.current);
            setTimeout(() => {
              // console.log("formmm", form);
              // const { el }: {el: unknown} = form ? form : { el: null };
              // const action = el && el.getAttribute("action");
              const action: null = null;
              // console.log(action);
              setFormUnlocked(true);
              // reset();
              // Submit the form using AJAX.
              //console.log(form)
              $.ajax({
                type: "POST",
                url: action,
                data: values,
              }).then(
                // temp = dataLayerObj.push(gtm) ,
                // setDataLayerObj(temp)
                Array.isArray(dataLayerObj) && (dataLayerObj.push(gtm) as never)
              );
              // .then(window.location.href = `https://esg-reports.com/`)
            }, 1000);
          }}
          //reactHubspotForm0
          //onReady={(form) => console.log("Form ready!")}
          loading={<div>Loading...</div>}
          //onClick={()=>console.log('clicked')}
        />

        <p className="never-share">
          Your information is never shared with outside parties.
        </p>
        <Logo white />
      </div>
    </Wrap>
  ) : null;
  //   }}
  // </HubUserContext.Consumer>
  // );
};

export default TileLock;
