import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
// import Img from "gatsby-image";
// import { HubUserContext, isValidUser } from "../../context/HubspotContext";
import { colors, WMKGradient } from "../../../vars/palette";
import { Img, WmkImage } from "wmk-image";

const Wrap = styled.div`
  width: 100%;
  ${WMKGradient.grad()}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  p {
    white-space: nowrap;
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: 200;
  }
  button,
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    color: ${colors.hex("white")};
    background: none;
    border: none;
    margin: 0 3%;
    text-decoration: none;
    :hover {
      text-decoration: none;
      p {
        color: ${colors.hex("codGray")};
      }
    }
  }
  .spacer {
    width: 100%;
    height: 2rem;
  }
`;

const DownloadShareBar = ({
  DlLink,
  DlIcon,
}: // ShareIcon,
// toggleShare,
{
  DlIcon: Img;
  DlLink: {
    title: string;
    file: {
      url: string;
    };
  };
}) => {
  const [validUser, setValidUser] = useState(false);

  useEffect(() => {
    if (window && localStorage?.getItem("hasCompletedForm")) {
      setValidUser(true);
    }
  }, []);
  // const DlFixed = DlIcon && DlIcon.fixed ? DlIcon.fixed : null;
  // const DlTitle = DlIcon && DlIcon.title ? DlIcon.title : null;
  //const ShareFixed = ShareIcon && ShareIcon.fixed ? ShareIcon.fixed : null;
  //const ShareTitle = ShareIcon && ShareIcon.title ? ShareIcon.title : null;
  return validUser ? (
    // <HubUserContext.Consumer>
    //   {(values) => {
    //     const { user } = values ? values : { user: null };
    //console.log(values)

    <Wrap>
      <a
        href={`https:` + DlLink.file.url}
        download
        target="_blank"
        rel="noreferrer"
        style={{ padding: ".5vh 0" }}
      >
        <WmkImage image={DlIcon} />
        <p>Download PDF</p>
      </a>
      {/* <a href="../../../assets/ESG_PDF.pdf" download target="_blank"><Img fixed={DlFixed} alt={DlTitle} /><p>Download PDF</p></a> */}
      {/* <button onClick={() => toggleShare()}>
              <Img fixed={ShareFixed} alt={ShareTitle} />
              <p>Share</p>
            </button> */}
    </Wrap>
  ) : (
    <Wrap>
      <div className="spacer" />
    </Wrap>
  );
  //   }}
  // </HubUserContext.Consumer>
};

export default DownloadShareBar;
