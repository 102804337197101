import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import HomePage, { HomeData } from "../components/routes/Home/HomePage";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import Layout from "../components/layout/MainLayout";
import { Img } from "wmk-image";
import { WmkSeo } from "wmk-seo";

// const DevUnlock = styled.button`
//   display: block;
//   position: fixed;
//   z-index: 10000;
//   top: 75px;
//   left: 50px;
//   padding: 1rem;
//   background: ${colors.hex("codGray")};
//   color: ${colors.hex("white")};
//   p {
//     margin-bottom: 0;
//   }
// `



const IndexPage = () => {
  const [showShare, setShowShare] = useState(false);
  // const [unlocked, setUnlocked] = useState(false)
  const toggleShare = () => {
    setShowShare(!showShare);
  };
  // const unlock = () => {
  //   setUnlocked(true)
  //   console.log("unlock() ran.")
  //   console.log("unlocked state: ", unlocked)
  // }

  const homeData: HomeData = useStaticQuery(query);
  const topics = homeData.topics.edges.map((p) => p.node);
  const heroImages = homeData.hero.edges.map((p) => new Img(p.node));
  const iconTexts = homeData.iconText.edges.map((p) => p.node);
  // const statusBar = homeData.statusBar
  const sharePopup = homeData.sharePopup;
  return (
    <Layout>
      <WmkSeo.Meta
        title={homeData.homeHeroAndIntro.title}
        path={"/"}
        slug={""}
        //description={description}
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      <HomePage
        heroImages={heroImages}
        iconTexts={iconTexts}
        sharePopup={sharePopup}
        toggleShare={toggleShare}
        topics={topics}
        showShare={showShare}
        homeData={homeData}
      />
    </Layout>
  );
};

export default IndexPage;

const query = graphql`
  query {
    homeHeroAndIntro: contentfulGlobal(
      contentfulid: { eq: "home-hero-and-intro" }
    ) {
      title
      subtitle
      contentfulid
      richText {
        raw
      }
    }
    homeSecondaryCopy: contentfulGlobal(
      contentfulid: { eq: "home-secondary-copy" }
    ) {
      richText {
        raw
      }
    }
    topics: allContentfulTopics(sort: { order: ASC, fields: sortOrder }) {
      edges {
        node {
          ...NodeHomeTopicsFields
        }
      }
    }
    hero: allContentfulAsset(
      filter: { title: { regex: "/ESG, The New Bottom Line/" } }
      sort: { order: ASC, fields: description }
    ) {
      edges {
        node {
          title
          gatsbyImageData(width: 3080, quality: 100)
        }
      }
    }
    downloadIcon: contentfulAsset(title: { eq: "Download Icon" }) {
      title
      gatsbyImageData(width: 25)
    }
    downloadLink: contentfulAsset(title: { eq: "ESG PDF" }) {
      title
      file {
        url
      }
    }
    shareIcon: contentfulAsset(title: { eq: "Share Icon" }) {
      title
      gatsbyImageData(width: 25)
    }
    iconText: allContentfulIconText(
      filter: { title: { regex: "/^((?!Actions|LP|Create|Gaiter).)*$/" } }
      sort: { order: ASC, fields: sortOrder }
    ) {
      edges {
        node {
          ...NodeIconTextFields
        }
      }
    }
    sharePopup: contentfulGlobal(contentfulid: { eq: "share-popup-copy" }) {
      title
      subtitle
      contentfulid
    }
  }
`;
